import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Globals } from '../globals';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class PaquetesService {


  constructor(private http: HttpClient,private globals:Globals) { }
  getAll() {
    return this.http.get(`${this.globals.apiUrl}/paquetes/getAllFront`).pipe(catchError(this.handleError));
  }

  agregar(paquete) {
    return this.http.post(`${this.globals.apiUrl}/paquetes/agregar`, {
      paquete
    }).pipe(catchError(this.handleError));
  }

  editar(paquete) {
    return this.http.post(`${this.globals.apiUrl}/paquetes/editar`, {
      paquete
    }).pipe(catchError(this.handleError));
  }

  eliminar(id,categorias) {
    return this.http.post(`${this.globals.apiUrl}/paquetes/eliminar`, {
      id: id,
      categorias: categorias
    }).pipe(catchError(this.handleError));
  }
    public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  };
}
